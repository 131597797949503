import styled from "styled-components";
// Comopnents
import Header from "./Header";
import Footer from "./Footer";

const Container = styled.div`
  position: relative;
  background-color: #000;
`;

const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Layout = ({ children }) => {
  return (
    <Container>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
};

export default Layout;
