import { useState } from "react";
import styled from "styled-components";
import logo from "../images/logo.png";

import bg from "../images/divider.png";

const HeaderContainer = styled.div`
  position: sticky !important;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 80px;
  background: black;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  .shadow {
    background-color: #000000ba;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }

  @media (min-width: 32rem) .com_index .com_menu_tab {
    justify-content: space-between;
  }
`;

const Navbar = styled.header`
  position: relative;
  z-index: 100;
  height: 80px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    width: 80%;
    margin-left: 10%;
    zoom: 1;
  }
`;

const NavbarContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 24px;
  flex-grow: 1;

  .logo {
    display: flex;
    align-items: center;

    img {
      max-width: 52px;
      margin-right: 15px;
      border-radius: 50%;
    }

    h1 {
      color: #fff;
      margin-bottom: 0;
      margin-left: -8px;
      font-family: Candcu;
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    text-align: center;
    background-color: #000;
    margin: 0;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    transition: 0.3s ease-in;
    padding-left: 0;

    &.show-nav {
      transform: translateX(0%);
    }
  }

  .toggle-btn {
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 1000;
    padding: 5px;
    margin-right: 16px;
    background-color: transparent;
    border: none;

    span {
      display: block;
      width: 30px;
      height: 2px;
      background-color: #fff;
      transition: 0.2s ease-in;

      &:nth-child(1) {
        transform: translateY(-5px);
      }

      &:nth-child(3) {
        transform: translateY(5px);
      }
    }

    &.toggle-btn-active {
      span {
        &:nth-child(1) {
          transform: translateY(2px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-100%);
        }

        &:nth-child(3) {
          transform: translateY(-2px) rotate(-45deg);
        }
      }
    }
  }

  .connect-container {
    display: flex;
  }

  a {
    margin-bottom: 24px;
  }

  @media (min-width: 1200px) {
    .nav-links {
      flex-grow: 1;
      background: transparent;
      flex-direction: row;
      position: relative;
      transform: translateX(0);
      transition: none;

      a {
        margin-left: 24px;
        margin-bottom: 0;
      }

      a:first-child {
        margin-left: 0;
      }

      a:nth-child(2) {
        margin-left: auto;
      }
    }

    .toggle-btn {
      display: none;
    }

    .connect-container {
      margin-left: auto;
      margin-right: 32px;
    }
  }
`;

const MenuItem = styled.a`
  padding: 6px 24px;
  display: -moz-box;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  font-weight: 700;
  text-decoration: none;
  font-size: 22px;
  border-radius: 5px;
  border: 2px solid #ffffff73;
  color: #fff;
  font-family: Candcu;

  &:hover {
    box-shadow: 2px 2px 5px 0 #fff;
  }
`;

const Header = () => {
  const [isOpen, setNav] = useState(false);

  const toggleNav = () => {
    setNav((isOpen) => !isOpen);
  };

  return (
    <HeaderContainer>
      <div className="shadow"></div>
      <Navbar>
        <NavbarContent>
          <MenuContainer>
            <div className={isOpen ? "nav-links show-nav" : "nav-links"}>
              <a className="logo" href="/#home">
                <img src={logo} alt="" />
                <h1>HoHoHo</h1>
              </a>
              <MenuItem
                onClick={toggleNav}
                href="/#about
              "
              >
                $HoHoHo?
              </MenuItem>
              <MenuItem onClick={toggleNav} href="#airdrop">
                Airdrop
              </MenuItem>
              <MenuItem onClick={toggleNav} href="/#tokenomics">
                Tokenomics
              </MenuItem>
              <MenuItem onClick={toggleNav} href="/#roadmap">
                Roadmap
              </MenuItem>

              <div className="connect-container"></div>
            </div>
            <button
              className={isOpen ? "toggle-btn toggle-btn-active" : "toggle-btn"}
              type="button"
              onClick={toggleNav}
              aria-label="Menu Button"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </MenuContainer>
        </NavbarContent>
      </Navbar>
    </HeaderContainer>
  );
};

export default Header;
