import Layout from "./_shared/Layout";
import SideBar from "./_shared/SideBar";
import Banner from "./Banner";
import About from "./About";
import PreSale from "./PreSale";
import Tokenomics from "./Tokenomics";
import Roadmap from "./Roadmap";
import Snowfall from "react-snowfall";

const App = () => {
  return (
    <Layout>
      <Snowfall snowflakeCount={400} style={{ zIndex: "1000" }} />
      <SideBar />
      <Banner />
      <About />
      <PreSale />
      <Tokenomics />
      <Roadmap />
    </Layout>
  );
};

export default App;
