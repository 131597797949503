import styled from "styled-components";
import Divider from "./_shared/Divider";

import pepe1 from "./images/pepe1.png";
import pepe2 from "./images/pepe2.jpg";
import pepe3 from "./images/pepe3.jpg";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 120px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #005100;

  p {
    color: white;
    line-height: 36px;
    font-size: 18px;
    text-shadow: rgb(87, 20, 20) 0px 0px 10px, rgb(248, 248, 251) 0px 0px 10px;
  }

  @media (min-width: 420px) {
    padding: 120px 50px;
  }

  @media (min-width: 760px) {
    p {
      font-size: 20px;
    }
  }

  .shadow {
    background-color: #00000069;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  max-width: 1180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Orbitron;
  margin-top: 80px;

  h2 {
    font-size: 48px;
    color: #fff;
    font-family: Candcu;
    text-shadow: rgb(0, 0, 0) 0px 0px 10px, rgb(0, 0, 0) 0px 0px 10px;
  }

  p {
    margin-bottom: 80px;
  }

  @media (min-width: 360px) {
    h2 {
      font-size: 50px;
    }
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 60px;
    }
  }

  @media (min-width: 992px) {
    h2 {
      font-size: 80px;
    }
  }

  @media (min-width: 1180px) {
    h2 {
      font-size: 80px;
    }
  }
`;

const BoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Box = styled.div`
  max-width: 100%;
  background: #003000;
  box-shadow: 0 0px 12px #fff;
  border-radius: 0 36px;
  border: 0.02rem solid #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;

  p {
    margin: 0;
    padding: 24px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    max-width: 200px;
    border-radius: 0 0 0 36px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    width: 100%;
  }
`;

const PreSale = () => {
  return (
    <Section id="airdrop">
      <div className="shadow"></div>
      <Container>
        <h2>Airdrop</h2>
        <p>How to Participate?</p>
        <BoxesContainer>
          <Box>
            <img src={pepe1} alt="" />
            <p>
              As a token of appreciation for the wider Solana community, the
              $HoHoHo project will conduct an airdrop to eligible Solana wallet
              holders. This event is designed to foster inclusivity and reward
              those who have actively engaged with the Solana ecosystem.
            </p>
          </Box>
          <Box>
            <img src={pepe2} alt="" />
            <p>
              Eligibility criteria will be announced in advance, ensuring
              transparency and fairness throughout the airdrop process.
              Participants can anticipate receiving $HoHoHo.
            </p>
          </Box>
          <Box>
            <img src={pepe3} alt="" />
            <p>Token address: Coming soon!</p>
          </Box>
        </BoxesContainer>
      </Container>
      <Divider />
    </Section>
  );
};

export default PreSale;
