import styled from "styled-components";
import Line from "../images/divider.png";

const Divider = styled.img`
  width: 100%;
  position: absolute;
  bottom: -6px;
  z-index: 100;

  @media (min-width: 480px) {
    bottom: -6px;
  }

  @media (min-width: 760px) {
    bottom: -8px;
  }

  @media (min-width: 1180px) {
    bottom: -18px;
  }
`;

const Header = () => {
  return <Divider src={Line} alt="" />;
};

export default Header;
