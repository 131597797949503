import styled from "styled-components";
import Divider from "./_shared/Divider";
import bg from "./images/bg2.png";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 120px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #370808;

  p {
    color: white;
    line-height: 36px;
    font-size: 18px;
    text-shadow: rgb(0, 0, 0) 0px 0px 10px, rgb(0, 0, 0) 0px 0px 10px;
  }

  @media (min-width: 420px) {
    padding: 120px 50px;
  }

  @media (min-width: 760px) {
    p {
      font-size: 20px;
    }
  }

  .shadow {
    background-color: #00000069;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  // max-width: 1180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: Orbitron;

  @media (min-width: 992px) {
    flex-direction: row;
    max-width: 95%;
  }
`;

const LeftGrid = styled.div`
  display: flex;
  justify-content: center;
  order: 2;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  @media (min-width: 992px) {
    order: 1;
    flex-basis: 40%;
    max-width: 40%;

    img {
      width: 400px;
    }
  }
`;

const RightGrid = styled.div`
  order: 1;
  text-align: center;

  h2 {
    font-size: 48px;
    color: #fff;
    font-family: Candcu;
    text-shadow: rgb(87, 20, 20) 0px 0px 10px, rgb(248, 248, 251) 0px 0px 10px;
  }

  @media (min-width: 360px) {
    h2 {
      font-size: 50px;
    }
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 60px;
    }
  }

  @media (min-width: 992px) {
    flex-basis: 55%;
    max-width: 55%;
    order: 2;

    h2 {
      font-size: 80px;
    }
  }

  @media (min-width: 1180px) {
    h2 {
      font-size: 80px;
    }
  }
`;

const About = () => {
  return (
    <Section id="about">
      <div className="shadow"></div>
      <Container>
        <LeftGrid>
          <img src="https://c.tenor.com/3U7NHOgRhxUAAAAC/tenor.gif" alt="" />
        </LeftGrid>
        <RightGrid>
          <h2>What is $HoHoHo?</h2>
          <p>
            $HoHoHo, the Solana blockchain meme coin, is a festive and
            seasonally inspired coin that brings holiday cheer to the
            decentralized finance world.{" "}
          </p>
          <p>
            Named after the iconic laughter of Santa Claus 😉 , this coin
            embodies the spirit of giving and celebration during the Christmas
            season. Just as Santa spreads happiness and gifts during Christmas,
            $HoHoHo aims to bring smiles to the crypto community, creating a
            lighthearted and playful atmosphere in the world of digital assets.
          </p>
          <p>
            As investors and enthusiasts embrace the festive spirit, $HoHoHo
            becomes a unique and whimsical addition to the diverse landscape of
            meme coins on the Solana blockchain.
          </p>
        </RightGrid>
      </Container>
      <Divider />
    </Section>
  );
};

export default About;
