import styled from "styled-components";
import Divider from "./_shared/Divider";
import tokenomics from "./images/tokenomics.png";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 120px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #370808;

  p {
    color: white;
    line-height: 36px;
    font-size: 18px;
    text-shadow: rgb(87, 20, 20) 0px 0px 10px, rgb(248, 248, 251) 0px 0px 10px;
  }

  @media (min-width: 420px) {
    padding: 120px 50px;
  }

  @media (min-width: 760px) {
    p {
      font-size: 20px;
    }
  }

  .shadow {
    background-color: #00000069;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  max-width: 1180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Orbitron;

  h2 {
    font-size: 48px;
    color: #fff;
    font-family: Candcu;
    text-shadow: rgb(0, 0, 0) 0px 0px 10px, rgb(0, 0, 0) 0px 0px 10px;
  }

  @media (min-width: 360px) {
    h2 {
      font-size: 50px;
    }
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 60px;
    }
  }

  @media (min-width: 992px) {
    max-width: 75%;
    order: unset;
    margin-top: 0;

    h2 {
      font-size: 80px;
    }
  }

  @media (min-width: 1180px) {
    h2 {
      font-size: 80px;
    }
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 48px;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const Box = styled.div`
  border: 1px solid #d23434e0;
  border-radius: 10px;
  margin-bottom: 48px;

  h3 {
    padding: 24px;
    border-bottom: 1px solid #d23434e0;
    color: #fff;
    font-family: Orbitron;
    text-align: center;
  }

  p {
    text-align: center;
    padding: 24px;
  }

  @media (min-width: 992px) {
    flex-basis: 30%;
    max-width: 30%;
    margin-bottom: 0;
  }
`;

const TokenomicsContainer = styled.div`
  display: flex;

  img {
    max-width: 100%;
  }
`;

const Tokenomics = () => {
  return (
    <Section id="tokenomics">
      <div className="shadow"></div>
      <Container>
        <h2>Tokenomics</h2>
        <p></p>
        <BoxContainer>
          <Box>
            <h3>Token Supply</h3>
            <p>555.555.555.555 $HOHOHO</p>
          </Box>
          <Box>
            <h3>Liquidity Pool</h3>
            <p>Locked and Renounced after launch</p>
          </Box>
          <Box>
            <h3>Tax</h3>
            <p>$HOHOHO has 0% taxes</p>
          </Box>
        </BoxContainer>
        <TokenomicsContainer>
          <img src={tokenomics} alt="" />
        </TokenomicsContainer>
      </Container>
      <Divider />
    </Section>
  );
};

export default Tokenomics;
