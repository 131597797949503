import styled from "styled-components";
import Divider from "./_shared/Divider";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import logo from "./images/logo.png";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 120px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #005100;

  p {
    color: #fff;
    line-height: 36px;
    font-size: 18px;
  }

  @media (min-width: 420px) {
    padding: 120px 50px;
  }

  @media (min-width: 760px) {
    p {
      font-size: 20px;
    }
  }

  .shadow {
    background-color: #00000069;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  max-width: 1180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Orbitron;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const LeftGrid = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  margin-top: 80px;

  order: 1;
  text-align: center;

  h2 {
    font-size: 48px;
    color: #fff;
    font-family: Candcu;
  }

  .socials {
    a {
      display: inline-block;
    }

    img {
      width: 40px;
      margin-right: 16px;
    }
  }

  @media (min-width: 360px) {
    h2 {
      font-size: 50px;
    }
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 60px;
    }
  }

  @media (min-width: 992px) {
    max-width: 75%;
    order: unset;
    margin-top: 0;

    h2 {
      font-size: 80px;
    }
  }

  @media (min-width: 1180px) {
    h2 {
      font-size: 80px;
    }
  }

  .no-shadow {
    img {
      max-width: 60px;
      height: auto;
      border-radius: 50%;
    }

    p {
      font-size: 16px;
      line-height: 32px;
    }
  }

  .vertical-timeline::before {
    width: 2px;
    background-color: #fff;
  }

  .no-shadow .vertical-timeline-element-content {
    box-shadow: unset;
  }

  .vertical-timeline-element-title {
    font-size: 24px;
    color: #fff;
    font-family: Orbitron;
  }

  .vertical-timeline-element-subtitle {
    color: #fff;
  }
`;

const Roadmap = () => {
  return (
    <Section id="roadmap">
      <div className="shadow"></div>
      <Container>
        <LeftGrid>
          <h2>Roadmap</h2>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="no-shadow"
              contentStyle={{
                background: "unset",
                color: "",
                border: "2px solid #fff",
                borderRadius: "10px",
              }}
              contentArrowStyle={{ borderRight: "7px solid  #7790da" }}
              date=""
              iconStyle={{ color: "" }}
              icon={<img src={logo} alt="Minguin Launch" />}
            >
              <h3 className="vertical-timeline-element-title">
                Holiday Giveaways Extravaganza
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Gib gib gib
              </h4>
              <p>
                To kick off the festive season, the $HoHoHo is hosting a series
                of massive giveaways. These giveaways are our way of expressing
                gratitude to the community for their unwavering support.
              </p>
              <p>
                During this period, holders of the HoHoHo NFT will have the
                chance to win BIG AMOUNTS OF $HOHOHO. The giveaways aim to
                spread joy and cheer, embodying the true spirit of the holiday
                season.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="no-shadow"
              contentStyle={{
                background: "unset",
                color: "",
                border: "2px solid #fff",
                borderRadius: "10px",
              }}
              contentArrowStyle={{ borderRight: "7px solid  #7790da" }}
              date=""
              iconStyle={{ color: "" }}
              icon={<img src={logo} alt="Minguin Shredder" />}
            >
              <h3 className="vertical-timeline-element-title">
                Airdrop to Eligible Solana Communities
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                To the loudest communities in sol.
              </h4>
              <p>
                As a token of appreciation for the wider Solana community, the
                $HoHoHo project will conduct an airdrop to eligible Solana
                wallet holders. This event is designed to foster inclusivity and
                reward those who have actively engaged with the Solana
                ecosystem.
              </p>
              <p>
                Eligibility criteria will be announced in advance, ensuring
                transparency and fairness throughout the airdrop process.
                Participants can anticipate receiving $HoHoHo.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="no-shadow"
              contentStyle={{
                background: "unset",
                color: "",
                border: "2px solid #fff",
                borderRadius: "10px",
              }}
              contentArrowStyle={{ borderRight: "7px solid  #7790da" }}
              date=""
              iconStyle={{ color: "" }}
              icon={<img src={logo} alt="Minguin DAO" />}
            >
              <h3 className="vertical-timeline-element-title">
                Christmas Extravaganza
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                (December 25, 2023)
              </h4>
              <p>
                On Christmas Day, the HoHoHo will host a grand event............
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="no-shadow"
              contentStyle={{
                background: "unset",
                color: "",
                border: "2px solid #fff",
                borderRadius: "10px",
              }}
              contentArrowStyle={{ borderRight: "7px solid  #7790da" }}
              date=""
              iconStyle={{ color: "" }}
              icon={<img src={logo} alt="$MINGUI token" />}
            >
              <h3 className="vertical-timeline-element-title">
                New Year Token Burn Event{" "}
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                (December 31, 2023)
              </h4>
              <p>
                As the year comes to a close, the $HoHoHo will conduct a New
                Year Token Burn event. This strategic move aims to enhance the
                scarcity and value of the $HoHoHo by reducing the overall token
                supply.
              </p>
              <p>
                {" "}
                A portion of the project's tokens will be permanently removed
                from circulation, aligning with a deflationary mechanism to
                benefit long-term holders. The event will be accompanied by
                exclusive perks and bonuses for those who have been part of the
                $HoHoHo journey.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ color: "" }}
              // icon={<StarIcon />}
            />
          </VerticalTimeline>
        </LeftGrid>
      </Container>
      <Divider />
    </Section>
  );
};

export default Roadmap;
